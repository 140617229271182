<template>
    <!-- 订单取消路由 -->
    <div>
        <!-- <cancel-apply></cancel-apply> -->
        <cancel-handle></cancel-handle>
    </div>
</template>

<script>
export default {
    components: {
        cancelApply: () => import("@/components/orderCancelApply"),
        cancelHandle: () => import("@/components/orderCancelHandle"),
    },
}
</script>

<style scoped>

</style>
